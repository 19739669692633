<template>
  <div style="height: 100%">
    <nesi-user-common :title="`绑定企业`">
      <template #content>
        <!-- <el-form
          :model="form"
          label-width="120px"
          ref="formRef"
          :rules="formRules"
          style="margin: 20px 0 30px; border: solid 1px #e5e5e5"
        >
          <el-form-item
            label="企业名称"
            prop="companyName"
            style="padding-top: 22px"
          >
            <el-input
              v-model="form.companyName"
              placeholder="请输入企业全称"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">绑定</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form> -->
        <el-table
          border
          :data="qyList"
          style="width: 100%; margin-bottom: 40px; margin-top: 20px"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <el-table-column prop="qymc" label="企业名称" width="250" />
          <el-table-column prop="qylx" label="企业类型" width="120" />
          <el-table-column prop="qyzcdz" label="办公地址" />
          <el-table-column prop="bindCompany" label="绑定状态" width="100">
            <template #default="scope">
              {{ scope.row.bindCompany === 1 ? '已绑定' : '未绑定' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button
                v-if="scope.row.bindCompany === 0"
                type="text"
                size="small"
                class="list-btn"
                @click="bind(scope.row)"
                >绑定企业
              </el-button>
              <el-button
                v-else
                type="text"
                size="small"
                class="list-btn"
                @click="unbind(scope.row)"
                >解除绑定
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tip-wrapper">
          <div class="title">
            <i class="el-icon-warning-outline mr-2"></i> 温馨提示：
          </div>
          <div class="content">
            <p>
              绑定企业前，请先在南宁市房屋交易监管系统完成企业备案，才可进行绑定。
            </p>
          </div>
        </div>
      </template>
    </nesi-user-common>
  </div>
</template>
<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { defineComponent, ref, onMounted } from 'vue'
import {
  bindEnterprise,
  queryUserCompanyName,
  unBindEnterprise
} from '@/api/login'
import { ElMessage, ElMessageBox } from 'element-plus'

defineComponent({
  NesiUserCommon
})

const qyList = ref([])
const userCompanyName = async () => {
  const resp = await queryUserCompanyName()
  qyList.value = resp
}
onMounted(() => {
  userCompanyName()
})

const bind = async (item) => {
  await ElMessageBox.confirm('确定绑定该企业吗？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      const resp = await bindEnterprise({ companyId: item.qyid })
      ElMessage.success(resp)
      userCompanyName()
    })
    .catch(() => {})
}

const unbind = async (item) => {
  await ElMessageBox.confirm('确定解除对该企业绑定吗？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      const resp = await unBindEnterprise({ companyId: item.qyid })
      ElMessage.success(resp)
      userCompanyName()
    })
    .catch(() => {})
}
</script>
<style lang="scss" scoped>
.w-p-47 {
  width: 47%;
}
.w-p-97 {
  width: 97%;
}
.tip-wrapper {
  color: #ff9900;
  background: #fdf6ec;
  padding: 10px;
  border-radius: 5px;

  .title {
    font-size: 14px;
  }
  .content {
    margin-top: 5px;
    // padding-left: 10px;
    font-size: 13px;

    p {
      height: 20px;
      line-height: 20px;
    }
  }
}
</style>
